<template>
	<div class="ov_y" :style="{height:height+'px'}">
		<div class="bg">
			<!-- 搜索条件 -->
        <el-form ref="form" :model="sizeForm" label-width="100px" size="mini">
            <el-row>
                <el-col :span="4">
                    <el-form-item label="选择时间：">
                        <el-select placeholder="请选择相应时间" clearable v-model="sizeForm.status">
                            <el-option
								v-for="item in options"
								:key="item.value"
								:label="item.label"
								:value="item.value" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="门店ID：">
                        <el-input placeholder="请输入门店ID" v-model="sizeForm.store_id"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="2">
                    <el-form-item >
                        <el-button size="small" type="primary" @click="onSearch ">搜索</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
			<el-row>
			<el-col class="bos" :span="8">
				<div class="con">
					<div class="title">
						<img style="width: 20px; vertical-align: -4px;" src="../image/yhm.png" alt="">  总用户统计
					</div>
					<div id="histogram" style="width: 100%; height: 90%;"></div>
				</div>
			</el-col>
			<el-col  class="bos" :span="8">
				<div class="con">
					<div class="title">
						<img style="width: 16px; vertical-align: -4px;" src="../image/dd.png" alt="">  日订单总量 <span style="color: #6665FF; font-size: 22px; font-weight: bold;">{{order_num}}</span> 
					</div>
					<div>
						<div id="myChart" :style="{width: width+'px', height: '360px'}"></div>
					</div>
				</div>
			</el-col>
			<el-col  :span="8">
				<div>
					<div class="con" style="height: 200px;">
						<el-row style="text-align: center; box-sizing: border-box; padding-top:44px;">
							<el-col  :span="12">
								<div style="margin-bottom: 10px;">
									<img style="width: 50px;" src="../image/je.png" alt="">
								</div>
								<div style="color: #7E7CF8; line-height: 30px; font-size:22px; font-weight: bold">{{order_total}}</div>
								<div style="line-height:24px;">成交额(元)</div> 
							</el-col>
							<el-col   :span="12">
								<div style="margin-bottom: 10px;">
									<img style="width: 50px;" src="../image/spgl.png" alt="">
								</div>
								<div style="color: #EB5130; line-height: 30px; font-size:22px; font-weight: bold">{{num}}</div> 
								<div style="line-height: 24px;">成交商品量(件)</div> 
							</el-col>
						</el-row>
					</div>
					<div class="con" style="height: 160px; line-height: 160px; margin-top: 60px; font-size: 20px;">
						<img style="width: 50px;  margin-left: 122px; vertical-align: -20px;" src="../image/md.png" alt="">  当前门店数量 <span style="color: #EB5130; font-size: 26px; font-weight: bold;">{{store_total}}</span>
					</div>
				</div>
			</el-col>
			</el-row>
			<el-row style="margin-top:10px">
				<el-col :span="12">
					<div class="con">
						<div class="title">
							<img style="width: 20px; vertical-align: -4px;" src="../image/phb.png" alt="">  商品销售排行 
							<el-select placeholder="请选择相应排行" v-model="sizeForm.goodsRank">
                            	<el-option
								v-for="item in goodsRankList"
								:key="item.value"
								:label="item.label"
								:value="item.value" />
                        	</el-select>
						</div>
						<div>
							<el-table
								height="320"
								:data="tableData"
								style="width: 100%;line-height: 64px;">
								<el-table-column
								align="center"
								prop="index"
								label="排名"
								>
								<template slot-scope="scope">
									<div v-if="scope.row.index==1"><img style="vertical-align: -6px;" src="../image/jp.png" alt="">{{scope.row.index}}</div> 
									<div v-else-if="scope.row.index==2"><img style="vertical-align: -6px;"   src="../image/yp.png" alt="">{{scope.row.index}}</div> 
									<div v-else-if="scope.row.index==3"><img style="vertical-align: -6px;"  src="../image/tp.png" alt="">{{scope.row.index}}</div>  
									
									<div v-else style="margin-left: 20px;">{{scope.row.index}}</div>
									
								</template>
								</el-table-column>
								<el-table-column
								align="center"
								prop="goods_name"
								label="商品名称"
								>
								</el-table-column>
								<el-table-column
								align="center"
								prop="total_num"
								label="成交数量">
								</el-table-column>
								<el-table-column
								align="center"
								prop="total_price"
								label="成交总额">
								</el-table-column>
							</el-table>
						</div>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="con">
						<div class="title">
							<img style="width: 20px; vertical-align: -4px;" src="../image/phb.png" alt="">  门店销售排行 
							<el-select placeholder="请选择相应排行" v-model="sizeForm.orderRank">
                            	<el-option
								v-for="item in orderRankList"
								:key="item.value"
								:label="item.label"
								:value="item.value" />
                        	</el-select>
						</div>
						<div>
							<el-table
								height="320"
								:data="orderPriceRankList"
								style="width: 100%;line-height: 64px;">
								<el-table-column prop="index" label="排名" align="center">
									<template slot-scope="scope">
										<div v-if="scope.row.index==1"><img style="vertical-align: -6px;" src="../image/jp.png" alt="">{{scope.row.index}}</div> 
										<div v-else-if="scope.row.index==2"><img style="vertical-align: -6px;"   src="../image/yp.png" alt="">{{scope.row.index}}</div> 
										<div v-else-if="scope.row.index==3"><img style="vertical-align: -6px;"  src="../image/tp.png" alt="">{{scope.row.index}}</div>
										<div v-else style="margin-left: 20px;">{{scope.row.index}}</div>
									</template>
								</el-table-column>
								<el-table-column
								align="center"
								prop="store_name"
								label="门店名称"
								>
								</el-table-column>
								<el-table-column
								align="center"
								prop="store_id"
								label="门店ID">
								</el-table-column>
								<el-table-column
								align="center"
								prop="order_count"
								label="总订单数">
								</el-table-column>
								<el-table-column
								align="center"
								prop="pay_price"
								label="总销售额">
								</el-table-column>
							</el-table>
						</div>
					</div>
				</el-col>		
			</el-row>
			<el-row style="margin-top: 10px;">
				<el-col :span="24">
					<div class="con">
						<div class="title">
							<img style="width: 20px; vertical-align: -4px;" src="../image/zx.png" alt=""> 门店成交趋势 
						</div>
						<div>
							<div id="main" :style="{width: width+'px', height: '320px'}"></div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import { index } from "@/api/api.js";
	export default {
		name: "statistics",
	  data() {
	    return {
			width:'',
			height:'',
			tableData:[],
			orderPriceRankList: [],
			user_num:0,
			vip_num:0,
			vip_total:0	,
			this_height:0,
			user_num_height:0,
			vip_total_height:0,
			vip_num_height:0,
			order_num:0,
			order_total:0,
			store_total:0, 
			num:0,
			goodsRankList:[{
	          value: 'total_num',
	          label: '下单商品数量'
	        },{
	          value: 'total_price',
	          label: '成交额'
	        }],
			orderRankList:[{
	          value: 'order_count',
	          label: '下单数量'
	        },{
	          value: 'pay_price',
	          label: '成交额'
	        }],
			sizeForm: {
				store_id: '',//门店id
				status: '',//获取时间段
				goodsRank: 'total_num',//下单商品数量排序
				orderRank: 'pay_price',//下单数量排序
				startTime: '',//开始时间时间戳
				endTime: '',//结束时间时间戳
			},
	        options: [{
	          value: '1',
	          label: '本周'
	        }, {
	          value: '2',
	          label: '上周'
	        }, {
	          value: '3',
	          label: '今天'
	        }, {
	          value: '4',
	          label: '上月'
	        }, {
	          value: '5',
	          label: '本月'
	        }, {
	          value: '6',
	          label: '昨天'
	        }, {
	          value: '7',
	          label: '本季度'
	        }, {
	          value: '8',
	          label: '上季度'
	        }, {
	          value: '9',
	          label: '今年'
	        }, {
	          value: '10',
	          label: '上年'
	        }, {
	          value: '11',
	          label: '近7天'
	        }],
			};
	  },
	  // ，8.获取上季度的开始与结束时间戳，9.获取今年的开始与结束时间戳，10.获取上年的开始与结束时间戳，11.获取近7天的开始与结束时间戳
	  mounted() {
		  this.getindex()
	  },
	  methods: {
		  change(){
			    this.getindex()
		  },
		  onSearch(){
				this.getindex()
		  },
		  async getindex() {
		    const { data } = await index(this.sizeForm);
			console.log(data)
		    if (data.code != 200) return this.$message.error(data.data);
			console.log(data)
			data.data.goods_num_rank.forEach((item,index)=>{
				// data.data.goods_num_rank.index=index+1
				item.index=index+1
			})
			data.data.order_price_rank.forEach((item,index)=>{
				item.index=index+1
			})
			console.log(data.data.goods_num_rank)
			this.tableData=data.data.goods_num_rank
			this.orderPriceRankList = data.data.order_price_rank
			let drawLine_data= [
			    {value: data.data.take_order_num, name: '自提订单数'},
			    {value: data.data.send_order_num, name: '配送订单数'},
			    {value: data.data.order_refund_num, name: '退款订单数'},
			]
			this.drawLine(drawLine_data)
			let time=[]
			let datas=[]
			data.data.order_price_trend.forEach((item,index)=>{
				time.push(item.pay_time)
				datas.push(item.pay_price)
			})
			this.histogramData(data.data)
			this.drawLine1(time,datas)
			this.num=data.data.take_order_num+data.data.send_order_num
			this.order_total=data.data.order_total
			this.order_num=data.data.order_num
			// this.user_num=data.data.user_num
			// this.vip_num=data.data.vip_num
			// this.vip_total=data.data.vip_total
			this.store_total=data.data.store_total
			this.this_height=(this.user_num/300)*this.user_num
			// this.user_num_height=(this.user_num/30)*this.user_num / 2000
			// this.vip_total_height=(this.user_num/300)*this.vip_total / 1000
			// this.vip_num_height=(this.user_num/300)*this.vip_num / 1000
			
			console.log(this.this_height,'this.this_height')
		  },
			//   用户数量柱状图
			histogramData(linData){
				console.log(linData)
				let myChart = this.$echarts.init(document.getElementById('histogram'))
				let option = {
					xAxis: {
						type: 'category',
						data: ['总用户量', '新增用户量','VIP用户量', '新增VIP用户量']
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						type: 'bar',
						barWidth : 50,
						data: [linData.user_num,linData.adduser_num,linData.vip_total,linData.vip_num],
						// data: [{
						// 	value: linData.user_num,
						// 	itemStyle: {
						// 		color: '#0CA294'
						// 	},
						// 	value: linData.vip_total,
						// 	itemStyle: {
						// 		color: '#EE8031'
						// 	},
						// 	value: linData.vip_num,
						// 	itemStyle: {
						// 		color: '#EE8031
						// 	}
						// }],
						itemStyle: {
							normal: {
								label: {
									show: true, //开启显示
									position: 'top', //在上方显示
									textStyle: { //数值样式
										color: 'black',
										fontSize: 16
									}
								},
								
								color:function(params){
									var colorlist = ['#0CA294','#EE8031','#7E7CF8','#77DA82'];
									return colorlist[params.dataIndex];
								}
							}
						},
					}]
				};
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
			},
		   drawLine(data){
				  var myChart = this.$echarts.init(document.getElementById('myChart'))
				  let option = {
				      tooltip: {
				          trigger: 'item'
				      },
				      legend: {
				          top: '5%',
				          left: 'center'
				      },
				      series: [
				          {
				              name: '',
				              type: 'pie',
				              radius: ['40%', '70%'],
							  color: ['#6665FF','#FFA765','#77DA82'],
				              avoidLabelOverlap: false,
				              itemStyle: {
				                  borderRadius: 10,
				                  borderColor: '#fff',
				                  borderWidth: 2
				              },
				              label: {
				                  show: false,
				                  position: 'center'
				              },
				              emphasis: {
				                  label: {
				                      show: true,
				                      fontSize: '20',
				                      fontWeight: 'bold'
				                  }
				              },
				              labelLine: {
				                  show: false
				              },
				              data:data
				          }
				      ]
				  };
		          
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
		      },
			  drawLine1(time,data){
				  let h=document.documentElement.clientHeight || document.body.clientHeight
				  this.height=h-50
				  // var chartDom = document.getElementById('main');
				  var myCharts = this.$echarts.init(document.getElementById('main'))
				  // var options;
				  
				  let options = {
				      title: {
				          text: ''
				      },
				      tooltip: {
				          trigger: 'axis'
				      },
				      legend: {
				          data: ['今日营销金额']
				      },
				      grid: {
				          left: '8%',
				          right: '4%',
				          bottom: '8%',
				          containLabel: false
				      },
				      toolbox: {
				          feature: {
				              saveAsImage: {}
				          }
				      },
				      xAxis: {
				          type: 'category',
				          boundaryGap: true,
				          data: time
				      },
				      yAxis: {
				          type: 'value'
				      },
				      series: [
				          {
				              name: '今日营销金额',
				              type: 'line',
				              stack: '金额',
							  color: ['#6665FF'],
				              data: data
				          }
				      ]
				  };
	
				  myCharts.setOption(options);
			  }
	  },
	};
</script>

<style>
	.por{
		position: relative; 
		box-sizing: border-box; 
		height: 360px; 
		text-align: center;
	}
	.por_two{
		position: absolute; 
		z-index: 1; 
		width: 100%; 
		bottom: 60px;
	}
	.box{
		box-sizing: border-box; 
		padding: 0 20px !important;
	}
	.con{ 
		box-shadow: #D9D9D9 0px 3px 9px; 
		width: 98%; 
		height: 420px;
		margin: auto; 
		border-radius: 8px; 
		padding-bottom: 10px;
	}
	.text{
		position: absolute;
		z-index: 1; 
		bottom: 0; 
		left: 0; width: 100%; 
		line-height: 30px;
	} 
	.poa{ 
		position: absolute; 
		z-index: 1; 
		bottom: 0px; 
		left: 50%; 
		margin-left: -20px;
	}
	.title{
		line-height: 50px; 
		font-weight: bold; 
		font-size: 16px; 
		box-sizing: border-box; 
		padding: 0 20px;
	}
	.bg{
		box-sizing: border-box; 
		padding:0 10px; 
		min-height: 100%; 
		background: #fff;
	}
	.ov_y{ 
		overflow-y: scroll;
	}
	.bg .el-table /deep/td /deep/.cell {
		line-height: 34px; 
		text-align: center;
	}
	.bg .el-table .has-gutter th{
		background: #409EFF; 
		color: #fff; 
		text-align: center;
	}
	.bg .el-table th, .el-table tr{
		line-height: 40px;
	}
	
	/* .has-gutter{background: #409EFF; color: #fff;} */
</style>

